import './styles/About.css';

export default function About() {
  return (
    <div className="about">
    <header className="header">
      <h1><code>mico gongob</code></h1>
      <div className="social__wrapper">
        <a href="https://github.com/micov6" target={'_blank' /* eslint-disable-next-line */}>
          <i className="fab fa-github fa-lg"></i>
        </a>
        <a href="https://www.linkedin.com/in/mgongob/" target={'_blank' /* eslint-disable-next-line */}>
          <i className="fab fa-linkedin fa-lg"></i>
        </a>
        <a href="https://medium.com/@micogongob" target={'_blank' /* eslint-disable-next-line */}>
          <i className="fab fa-medium fa-lg"></i>
        </a>
        <a href="mailto:megongob@gmail.com" target={'_blank' /* eslint-disable-next-line */}>
          <i className="far fa-envelope fa-lg"></i>
        </a>
      </div>
    </header>
    <section className="content__wrapper">
      <main className="main__container">
        <div className="vertical__line"></div>
        <ol>
          <li>
            <code>&nbsp;&nbsp;
              <span className="span__text--blue">public class</span>
              <span className="span__text--black"> Home</span> &#123;
            </code>
          </li>
          <li>
            <code>&nbsp;&nbsp;
              // Do you know HTML?
            </code>
          </li>
          <li>
            <code>&nbsp;&nbsp;
              // How To Make Lumpia
            </code>
          </li>
          <li>
            <code>&nbsp;&nbsp;&nbsp;
              <span className="span__text--blue">record </span>
              <span className="span__text--red">Person</span>(
              <span className="span__text--blue">String</span>&nbsp;job,
              <span className="span__text--blue"> boolean</span>&nbsp;lovesLumpia)&nbsp;&#123;&nbsp;&#125;
            </code>
          </li>
          <li></li>
          <li>
            <code>&nbsp;&nbsp;&nbsp;
              <span className="span__text--blue">public static void&nbsp;</span>
              <span className="span__text--black">main</span>(
              <span className="span__text--blue">String</span>
              <span className="span__text--black">[]&nbsp;</span>args)&nbsp;&#123;
            </code>
          </li>
          <li>
            <code>&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="span__text--blue">var</span>&nbsp;
              <span className="span__text--black">mico</span>&nbsp;=&nbsp;
              <span className="span__text--blue">new</span>&nbsp;
              <span className="span__text--black">Person</span>(
              <span className="span__text--green">"Backend Engineer"</span>,
              <span className="span__text--blue"> true</span>);
            </code>
          </li>
          <li><code>&nbsp;&nbsp;&nbsp;&#125;</code></li>
          <li><code>&nbsp;&nbsp;&#125;</code></li>
        </ol>
      </main>
      <div className="tools">
        <ul>
          <li>
            <h1><span className="java">Java</span></h1>
          </li>
          <li>
            <h1><span className="spring-framework">Spring Framework</span></h1>
          </li>
          <li>
            <h1><span className="scala">Scala</span></h1>
          </li>
          <li>
            <h1><span className="akka">Akka</span></h1>
          </li>
          <li>
            <h1><span className="javascript">JavaScript</span></h1>
          </li>
          <li>
            <h1><span className="node">Node</span></h1>
          </li>
          <li>
            <h1><span className="typescript">Typescript</span></h1>
          </li>
          <li>
            <h1><span className="aws">AWS</span></h1>
          </li>
          <li>
            <h1><span className="kubernetes">Kubernetes</span></h1>
          </li>
          <li>
            <h1><span className="serverless">Serverless</span></h1>
          </li>
          <li>
            <h1><span className="terraform">Terraform</span></h1>
          </li>
          <li>
            <h1><span className="postgresql">PostgreSQL</span></h1>
          </li>
          <li>
            <h1><span className="html">HTML5</span></h1>
          </li>
          <li>
            <h1><span className="css">CSS3</span></h1>
          </li>
          <li>
            <h1><span className="react">React</span></h1>
          </li>
        </ul>
      </div>
    </section>
    </div>
  );
}
